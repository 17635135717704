import React from "react";

const ResetPassword = () => {
  return (
    <div className="signup">
      <div className="signup__main">
        <h1>Reset Password </h1>

        <p style={{ fontSize: 14, marginBottom: 30 }}>
          Kindly enter the email address associated with your metoda account,
          and we'll send you a mail to help you reset your password
        </p>

        <div className="signup__form">
          <div className="signup__form__item">
            <input type="email" placeholder="Email" />
          </div>

          <div className="signup__form__btn" style={{ marginTop: 30 }}>
            <button style={{ width: "170px" }} className="btn">
              <span>Send Password</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
