import React, { useEffect } from "react";
import Hero from "../components/Hero";
import Service from "../components/Service";
import WhyWork from "../components/WhyWork";
import Plan from "../components/Plan";
import Faq from "../components/Faq";

const Home = () => {
  const faqList = [
    {
      question: "How does Metoda help business owners succeed?",
      answer: `Metoda offer a comprehensive suite of services to establish a successful and memorable online presence. If you already have a website, our hosting plans ensure it remains fast, secure, and accessible. Our professional email services enhance your professional image, and our online marketing tools help entrepreneurs launch SEO-friendly websites. As an all-in-one solution provider, Metoda supports your online venture with expert, personalized assistance from our Metoda Guides.`,
    },
    {
      question: "Why choose Metoda?",
      answer: `Metoda is a technology provider that offers reliable and affordable Website Hosting plans, Domains, Website Design and Platform Integration service. At Metoda, we are recognized as a top choice not only for individuals but also for small and large businesses seeking to establish their online presence. We offer round-the-clock support to those who decide to join us on this journey.`,
    },
    {
      question: "What types of Web Hosting options are available?",
      answer: `There are primarily three categories of web hosting: Dedicated Server, Shared Hosting, and VPS (Virtual Private Server) Hosting. Dedicated server allocates an entire server to a single client, Shared Hosting allows multiple clients to utilize a single server's resources collectively, and VPS Hosting offers a middle ground by providing a partitioned space on a server that simulates a dedicated server environment for its users. Each type of hosting comes with its own set of advantages and limitations, and the best fit depends on the website’s specific requirements.`,
    },
    // {
    //   question: "Free domain name",
    //   answer: `We are pleased to provide a complimentary domain name with several of our hosting packages, which can be an excellent way to reduce costs and streamline your website setup. Please review the specifics of the hosting plan to confirm if a free domain name is included, and take a moment to read our complete policy regarding free domains.`,
    // },
    {
      question: "Can I transfer my domain to your service?",
      answer: `Yes, you can transfer your domain to our service. The process typically involves obtaining an authorization (EPP) code from your current provider, unlocking the domain, and initiating the transfer request through our platform. Once the transfer is started, it may take a few days to complete, depending on the domain extension and the current provider's policies. Our support team is available to assist you through each step of the process.`,
    },
    {
      question: "Why do I need Website for my business?",
      html: true,
      answer: ` Even small local businesses that serve only their local community need a website, as the internet is often the first place people turn to when searching for a product or service. A website benefits every business by enabling them to:
<ul> 
<li> Promote and sell their products and services</li>
<li>Engage with new customers and retain existing ones</li>
<li>Enhance credibility</li>
<li>Compete with larger companies</li>
<li>Maintain control over their brand and keep their marketing strategies up-to-date</li>
</ul> 
 Furthermore, creating a website is much easier and more affordable than many might think. Metoda provides a comprehensive suite of online tools for website creation, alongside hosting, email, and marketing solutions to help businesses expand their online presence. `,
    },
  ];

  const planList = [
    {
      name: "Hosting",
      price: "9",
      per: "/month",
      features: [
        "Intel Xeon-D CPU",
        "RAM",
        "HDD storage",
        "Unmetered bandwidth",
        "Cpanel included with Rooth access",
      ],
      link: "/services/hosting#pricing",
    },
    {
      name: "Domains",
      price: "3",
      per: "/year",
      features: [
        "Easy domain management",
        "Auto-renewal",
        "Domain lock",
        "Domain privacy",
        "Geo-location domains",
      ],
      alt: true,
      link: "/services/domain#pricing",
    },
    {
      name: "Website Design",
      price: "3.7",
      per: "/hour",
      features: [
        "Accessible to everyone",
        "Customized design solutions",
        "Responsive mobile-friendly",
        "User-centric approach",
        "Continuous support and maintenance",
      ],
      link: "/services/website-design#pricing",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home" style={{ maxWidth: "100vw", overflowX: "hidden" }}>
      <Hero />
      <Service />
      <WhyWork />
      <Plan planList={planList} />
      <Faq faqList={faqList} />
    </div>
  );
};

export default Home;
