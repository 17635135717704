import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(initReactI18next).init({
  supportedLngs: ["en", "sr"],
  fallbackLng: "en",
  lng: "en",
  // lng: getCurrentLang(),
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        homeLink: "Home",
        aboutLink: "About Us",
        serviceLink: "Services",
        hostingLink: "Hosting",
        sharedLink: "Shared",
        vpsLink: "VPS",
        dedicatedLink: "Dedicated Server",
        domainLink: "Domain",
        designLink: "Website Design",
        emailLink: "Professional Email",
        contactLink: "Contact",
        signin: "Sign In",
        heroTitle1: "Metoda",
        heroTitle2: "Technology",
        heroTitle3: "Provider",
        heroDisc:
          "Your digital partner for everything - from hosting to web design!",
        // getStarted: ""
      },
    },
    sr: {
      translation: {
        homeLink: "Pocetna",
        aboutLink: "O nama",
        serviceLink: "Usluge",
        hostingLink: "Hosting",
        sharedLink: "Shared",
        vpsLink: "VPS",
        dedicatedLink: "Dedicated Server",
        domainLink: "Domain",
        designLink: "Website Design",
        emailLink: "Professional Email",
        contactLink: "Kontakt",
        signin: "Prijavi Se",
        heroTitle1: "Metoda",
        heroTitle2: "Provajder",
        heroTitle3: "Tehnologije",
        heroDisc: "Vas digitalni partner za sve – od hostinga do veb dizajna!",
      },
    },
  },
});

export default i18n;
