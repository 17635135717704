import React, { useState } from "react";
import eye from "../assets/images/svg/eye.svg";
import eyeOff from "../assets/images/svg/eyeOff.svg";

const Signup = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="signup">
      <div className="signup__main">
        <h1>Create an account</h1>

        {/* <p className="signup__prev">Back to previous step.</p> */}

        <div className="signup__form">
          <div className="signup__form__item">
            <input type="email" placeholder="Email" />
          </div>
          <div className="signup__form__item alt">
            <input type="text" placeholder="Username" />
          </div>
          <div className="signup__form__item">
            <input type={show ? "text" : "password"} placeholder="Password" />
            <span onClick={() => setShow((curr) => !curr)}>
              <img src={show ? eyeOff : eye} alt="" /> {show ? "Hide" : "Show"}
            </span>
          </div>

          <div className="signup__form__offers">
            <p>
              I want to receive personalized offers, updates, and, information
              about Metoda products and services.{" "}
            </p>
            <div className="signup__form__offers__btns">
              <button className="alt">Accept</button>
              <button>Decline</button>
            </div>
          </div>

          <div className="signup__form__btn">
            <button className="btn">
              <span>create an account</span>
            </button>
          </div>

          <p className="signup__form__terms">
            By creating an account, you agree to GoDaddy’s{" "}
            <span>Terms & Conditions</span> and <span>Privacy Policy</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
