import social1 from "../assets/images/svg/social1.svg";
import social2 from "../assets/images/svg/social2.svg";
import social3 from "../assets/images/svg/social3.svg";

const Footer = ({ alt }) => {
  return (
    <footer className={alt ? "footer alt" : "footer"}>
      <div className="footer__main">
        <div className="footer__left">
          <p>Copyright © 2024 Metoda All rights reserved</p>
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>

        <div className="footer__right">
          {/* <img src={Facebook} alt="" />
          <img src={Twitter} alt="" />
          <img src={Instagram} alt="" />
          <img src={Dribbble} alt="" />
          <img src={Behance} alt="" /> */}
          <a
            href="https://www.instagram.com/metoda_srbija/?utm_source=qr&igsh=cnA1dzhwNGIzZTkx"
            target="_blank"
            rel="noreferrer"
          >
            <img src={social1} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/metoda-rs/about/?viewAsMember=true"
            target="_blank"
            rel="noreferrer"
          >
            <img src={social2} alt="" />
          </a>
          <a
            href="https://x.com/metoda_srbija"
            target="_blank"
            rel="noreferrer"
          >
            <img src={social3} alt="" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
