import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import logo from "../assets/images/svg/logo.svg";
import arrowBack from "../assets/images/svg/arrowBack.svg";
import Footer from "../components/Footer";

const AuthLayout = () => {
  const { pathname } = useLocation();

  return (
    <div className="authLayout">
      <div className="authLayout__top">
        <div className="authLayout__top__left">
          <div className="authLayout__top__left__back">
            {pathname === "/forgot-password" ? (
              <Link to="signin">
                <img src={arrowBack} alt="" /> Sign In
              </Link>
            ) : (
              <Link to="/">
                <img src={arrowBack} alt="" /> Go back
              </Link>
            )}
          </div>
        </div>

        <div className="authLayout__top__right">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </div>
      <Outlet />

      <Footer alt={true} />
    </div>
  );
};

export default AuthLayout;
