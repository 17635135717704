import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import hero from "../assets/images/heroWeb.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import featureWeb from "../assets/images/featureWeb.png";
import featureWebMobile from "../assets/images/featureWebMobile.png";
import Faq from "../components/Faq";

const Web = () => {
  const essentialList = [
    {
      title: "Credibility",
      disc: "Without a website, potential clients may question your business's  authenticity. A site with a 'contact us' page and customer testimonials can  enhance your reputation and establish credibility with your audience.",
    },
    {
      title: "Branding",
      disc: "Your brand is more than just a name; it includes colors, logos, and  the emotions it evokes. A well-designed website accurately represents your  brand and differentiates you from competitors.",
    },
    {
      title: "Organic Traffic",
      disc: "A live website will appear in Google's organic search results.  Proper SEO can attract thousands of free visitors through organic keywords,  leveling the playing field for smaller businesses. Discover how SEO can transform your website's visibility.",
    },
  ];

  const faqList = [
    {
      question: "What is custom website design and why is it important? ",
      answer: `Custom  website design involves creating a unique web presence tailored to your  brand's needs, goals, and target audience. It ensures your website stands out and aligns perfectly with your brand identity, providing better SEO  performance, scalability, and a superior user experience compared to template-based designs. `,
    },
    {
      question:
        "What is the process for creating a bespoke website with your company? ",
      answer: `The process of creating your bespoke website is streamlined into three efficient steps, which  can be completed in as little as three weeks: <br/>1. Initial Meeting: We gather your files and ideas, conduct keyword research, and begin the  design phase of your site. <br/>2. Post-Launch Review: After launching your personalized website, we conduct a thorough  review with you. You have a two-week window to request as many modifications as needed. <br/>3. Ongoing Adjustments: Once your website is live, you have the flexibility to make  adjustments on your own or collaborate with our expert team for further changes. `,
      html: true,
    },
    {
      question: "What are the key steps in the website design process? ",
      answer: `The website design process  generally includes an initial consultation to understand your goals, followed by keyword-rich content creation, design mockups, development, testing, and a final review to ensure everything aligns with your vision. Continuous support is provided post-launch to ensure  your site remains updated and optimized `,
    },
    {
      question: "How does SEO play a role in website design? ",
      answer: `SEO is crucial for enhancing your site's  visibility, driving traffic, and increasing engagement. Custom website designs incorporate SEO best practices, such as optimized site structure, fast load times, mobile responsiveness, and relevant keywords, to improve search engine rankings. `,
    },
    {
      question: "Will my website be mobile-friendly and responsive? ",
      answer: `Yes, ensuring your website is  mobile-friendly and responsive is a core aspect of modern web design. This means your site will provide an optimal viewing experience across all devices and screen sizes, which is essential for both user experience and SEO. `,
    },
    {
      question: "What sets Metoda apart from other web design companies? ",
      answer: `Metoda's personalized approach, modern design practices, and commitment to your business's growth distinguish us from others. We delve deep into your brand's essence to create not just websites, but digital experiences that narrate your story and engage your audience. `,
    },
    {
      question: "Can Metoda redesign my existing website? ",
      answer: `Absolutely! We specialize in updating your site's aesthetics, functionality, and performance to meet current standards and better reflect your brand's identity. `,
    },
    {
      question: "How can I ensure my website reflects my brand accurately? ",
      answer: `Through thorough consultation, market research, and your input, we tailor the website design to encapsulate your brand's identity and values. Our team works closely with you at various stages to ensure the final product aligns with your vision and expectations. `,
    },
    {
      question:
        "What ongoing support and maintenance services does Metoda offer? ",
      answer: `Metoda provides continuous support and maintenance to keep your website updated, secure, and performing efficiently. This includes regular updates, troubleshooting, and SEO adjustments as needed. `,
    },
    {
      question:
        "What are the benefits of hiring a professional web design service over doing it myself? ",
      answer: `Hiring a professional ensures you get a unique, custom design with specific functionalities tailored to your business needs. Professionals offer expertise in user experience, SEO, and  overall site performance, which can significantly enhance your website's effectiveness as a  business tool. `,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="web">
      <section className="hero">
        <div className="hero__header">
          <img
            src={headerBg}
            alt=""
            style={{ width: "80vw", maxWidth: "800px" }}
          />
          <h1 className="hero__header__title">
            <span>Web Design</span>
          </h1>
        </div>

        <p className="hero__disc" style={{ maxWidth: 550 }}>
          Design should not be seen as a luxury. It's essential that great
          design be made accessible to everyone.
        </p>

        <div className="hero__action">
          <HashLink to="#pricing">
            <button>
              <span>Get Started</span>
            </button>
          </HashLink>

          <p>from$2.99/mo*</p>
        </div>

        <img
          src={hero}
          className="hero__img vpsHero"
          alt=""
          style={{ marginTop: 27 }}
        />
      </section>

      <section className="web__essential">
        <h2 className="whyWork__main__title" style={{ flexWrap: "wrap" }}>
          Why is a <span>website essential</span> for your business?
        </h2>
        <p className="web__essential__disc">
          Every company, from tiny local stores in your neighborhood to the
          massive corporations listed on Forbes, requires a website. The
          benefits go beyond audience expansion, offering advantages such as:
        </p>

        <div className="web__essential__list">
          {essentialList.map((item, i) => {
            return (
              <div
                key={item.title}
                className={
                  i === 2
                    ? "web__essential__list__item big"
                    : "web__essential__list__item"
                }
              >
                <h4>{item.title}</h4>
                <p>{item.disc}</p>
              </div>
            );
          })}
        </div>
      </section>

      <div className="web__priceBg">
        <section className="web__feature">
          <img src={featureWeb} alt="" />
        </section>
        <section className="web__featureMobile">
          <h2 className="whyWork__main__title" style={{ flexWrap: "wrap" }}>
            <span>Key Features:</span>
          </h2>

          <img src={featureWebMobile} alt="" />
        </section>

        <section className="web__price" id="pricing">
          <div className="web__price__main">
            <div className="web__price__left">
              <h4>Unlimited</h4>
              <p>Graphic Design and Web Development</p>

              <button className="btn">
                <span>Inquire Now</span>
              </button>
            </div>

            <div className="web__price__right">
              <div>
                <span className="web__price__right__start">Starting from</span>
                <div className="web__price__right__main">
                  <p>$</p>{" "}
                  <div className="web__price__right__main__amount">
                    {" "}
                    <b>400</b>
                    <span>/month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Faq faqList={faqList} alt={true} />
      </div>
    </div>
  );
};

export default Web;
