import React, { useState } from "react";
import logo from "../assets/images/svg/logo.svg";
import LinkSubMenu from "./LinkSubMenu";
import { useLocation } from "react-router-dom";
import arrowDown from "../assets/images/svg/arrowDown.svg";
import arrowDownAlt from "../assets/images/svg/arrowDownAlt.svg";
import flag1 from "../assets/images/svg/flag1.svg";
import flag2 from "../assets/images/svg/flag2.svg";
import check2 from "../assets/images/svg/check2.svg";
import { HashLink } from "react-router-hash-link";
import { Menu } from "@szhsin/react-menu";
import Drawer from "./Drawer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Topbar = () => {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState("English");
  const { pathname } = useLocation();

  const links = [
    { name: t("homeLink"), url: "/" },
    { name: t("aboutLink"), url: "/about" },
    {
      name: t("serviceLink"),
      // url: "/service",
      subLinks: [
        {
          name: t("hostingLink"),
          url: "/services/hosting",
          subLinks: [
            { name: t("sharedLink"), url: "/services/shared" },
            { name: t("vpsLink"), url: "/services/vps" },
            { name: t("dedicatedLink"), url: "/services/dedicated-server" },
          ],
        },
        { name: t("domainLink"), url: "/services/domain" },
        { name: "Website Design", url: "/services/website-design" },
        { name: "Professional Email", url: "/services/professional-email" },
      ],
    },
    // { name: "Pricing", url: "/#pricing" },
    { name: t("contactLink"), url: "#contact" },
  ];

  const languages = [
    { name: "English", img: flag1, code: "en" },
    { name: "Serbian", img: flag2, code: "sr" },
  ];

  const handLangChange = (item) => {
    setLanguage(item.name);
    i18n.changeLanguage(item.code);
  };

  const TopBarLinks = () => {
    return (
      <div className="topbar__right">
        <ul className="topbar__right__links">
          {links.map((link) => {
            if (!link.subLinks) {
              return (
                <HashLink to={link.url} key={link.name}>
                  <li
                    className={
                      link.name === "Home" && pathname === "/"
                        ? "topbar__right__links__item alt"
                        : pathname.includes(link.url) && link.name !== "Home"
                        ? "topbar__right__links__item alt"
                        : "topbar__right__links__item"
                    }
                  >
                    {link.name}
                    <span className="topbar__right__links__item__dot">...</span>
                  </li>
                </HashLink>
              );
            } else {
              return <LinkSubMenu key={link.name} link={link} />;
            }
          })}
        </ul>

        <div className="topbar__right__btn">
          <Menu
            menuButton={
              <button>
                <span>{t("signin")}</span> <img src={arrowDownAlt} alt="" />
              </button>
            }
            transition
            menuClassName="topbar__right__btn__menu"
          >
            <div className="topbar__right__btn__popup">
              <img
                className="topbar__right__btn__popup__icon"
                src={arrowDown}
                alt=""
              />

              <div className="topbar__right__btn__popup__reg main">
                <span>Registered Users</span>
                <p>Have an account? Sign in now</p>

                <Link to="/signin">
                  <span className="topbar__right__btn__popup__reg__link">
                    {t("signin")}
                  </span>
                </Link>
              </div>

              <div className="topbar__right__btn__popup__reg">
                <span>New Customer?</span>
                <p>Create a new account </p>
                <Link to="/signup">
                  <span className="topbar__right__btn__popup__reg__link">
                    Sign Up
                  </span>
                </Link>
              </div>
            </div>
          </Menu>
        </div>

        <div className="">
          <Menu
            menuButton={
              <div className="topbar__right__flag">
                <img
                  className="topbar__right__flag__img"
                  src={language === "English" ? flag1 : flag2}
                  alt=""
                />{" "}
                <img
                  className="topbar__right__flag__icon"
                  src={arrowDown}
                  alt=""
                />
              </div>
            }
            transition
            menuClassName="topbar__right__flag__menu"
          >
            <div className="topbar__right__flag__popup">
              <img
                className="topbar__right__flag__popup__icon alt"
                src={arrowDown}
                alt=""
              />
              {languages.map((item) => {
                return (
                  <div
                    key={item.name}
                    className={
                      item.name === language
                        ? "topbar__right__flag__popup__item active"
                        : "topbar__right__flag__popup__item"
                    }
                    onClick={() => handLangChange(item)}
                  >
                    <img
                      className="topbar__right__flag__popup__item__img"
                      src={item.img}
                      alt=""
                    />
                    <p>
                      {item.name}{" "}
                      {item.name === language && <img src={check2} alt="" />}
                    </p>
                  </div>
                );
              })}
            </div>
          </Menu>
        </div>
      </div>
    );
  };

  return (
    <nav className="topbar">
      <div className="topbar__left">
        <Drawer TopBarLinks={TopBarLinks} links={links} />

        <Link to="/">
          <img className="topbar__left__logo" src={logo} alt="" />
        </Link>
      </div>

      <TopBarLinks />
    </nav>
  );
};

export default Topbar;
