import hero from "../assets/images/domainHero.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import domainWhy1 from "../assets/images/svg/domainWhy1.svg";
import domainWhy2 from "../assets/images/svg/domainWhy2.svg";
import domainWhy3 from "../assets/images/svg/domainWhy3.svg";
import domainWhy4 from "../assets/images/svg/domainWhy4.svg";
import arrowLeftD from "../assets/images/svg/arrowLeftD.svg";
import arrowRightD from "../assets/images/svg/arrowRightD.svg";
import important from "../assets/images/svg/important.svg";
import search from "../assets/images/svg/search.svg";
import essMobile from "../assets/images/essMobile.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
import Faq from "../components/Faq";

const Domain = () => {
  const [hide, setHide] = useState("left");
  const [hide2, setHide2] = useState("left");
  const [selectedCatHeader, setSelectedCatHeader] =
    useState("National Domains");

  const swiperRef = useRef();
  const swiperRef2 = useRef();

  const essList = [
    {
      title: "Stay Concise",
      disc: "There’s no set rule for the length of a domain name, but choosing one that’s fewer than three words is recommended. This makes your website name easier to recall and more distinctive.",
    },
    {
      title: "Keep it Simple",
      disc: "Exclude hyphens, numbers, slang, and words that are often misspelled from your domain names. Such elements can significantly complicate the memorability and accessibility of your website.",
    },
    {
      title: "Feature Your Brand Name",
      disc: "Ensure your website's address features your brand or key terms related to your niche. Having a keyword within your site's URL enhances brand visibility and can boost traffic to your website. ",
    },
    {
      title: "Check Domain Availability",
      disc: "Begin with a search for your desired domain name to see if it's available. It's also crucial to ensure that it hasn't been trademarked by someone else. ",
    },
    {
      title: "Consider Local Options",
      disc: "Although securing a .com domain is often preferred for its widespread recognition, these TLDs (Top-Level Domains) may not always be available. If your focus is on a specific country, think about choosing a country-specific extension such rs. , hr. , net. , ba. , to connect more directly with your target audience. ",
    },
    {
      title: "Seize the Opportunity",
      disc: "Top-tier domain names don't stay available for long. To avoid missing your perfect match, start with a domain search and acquire your domain names now, setting the stage for your online projects immediately",
    },
  ];

  const whyList = [
    {
      icon: domainWhy3,
      title: "Around the clock technical support",
      disc: `Our dedicated Customer Success 
team is available 24/7 to support you. While we've streamlined our domain 
registration to be straightforward, we understand that questions and needs can arise.`,
    },
    {
      icon: domainWhy1,
      title: "Respected Domain Registrar",
      disc: `Metoda holds the distinction of being an 
ICANN-accredited registrar and web hosting entity, winning the trust of 
countless individuals around the world.`,
    },
    {
      icon: domainWhy4,
      title: "Immediate Setup and Simplified Management",
      disc: `After you finish searching
for your doman, we'll asist you with the domain registration process. Just a few
clicks are needed, and you do not need any technical experitse to complete it.`,
    },
    {
      icon: domainWhy2,
      title: "Complimentary Privacy Protection ",
      disc: `Our domain privacy protection service ensures that your registration information remains confidential, shielding it from unauthorized third-party access for enhanced security. 
`,
    },
  ];

  const planList = [
    {
      name: ".com",
      price: "10",
    },
    {
      name: ".online",
      price: "2",
    },
    {
      name: ".me",
      price: "14",
    },
    {
      name: ".net",
      price: "8",
    },
    {
      name: ".org",
      price: "10",
    },
    {
      name: ".app",
      price: "14",
    },
    {
      name: ".co",
      price: "2",
    },
    {
      name: ".hr",
      price: "8",
    },
    {
      name: ".ba",
      price: "2",
    },
    {
      name: ".rs",
      price: "10",
    },
    {
      name: ".shop",
      price: "14",
    },
    {
      name: ".today",
      price: "8",
    },
  ];

  const catHeaders = [
    {
      name: "National Domains",
      shtName: "National",
      types: ".rs, .co.rs, .cp6",
    },
    {
      name: "International Domains",
      shtName: "International",
      types: ".com, .net, .org",
    },
    {
      name: "New Domains",
      shtName: "New",
      types: ".sale, .travel",
    },
  ];

  const tableData = {
    "National Domains": [
      {
        ext: ".RS",
        type: "Registration for all interested users",
        price: "$19.19",
        newPrice: "$17.80",
      },
      {
        ext: ".CO.RS",
        type: "Registration for business users",
        price: "$8.50",
      },
      {
        ext: ".ORG.RS",
        type: "Registration for business users",
        price: "$8.50",
      },
      {
        ext: ".EDU.RS",
        type: "Registration for educational organizations ",
        price: "$8.50",
      },
      {
        ext: ".IN.RS",
        type: "Registration for individuals",
        price: "$5.40",
      },
      {
        ext: ".CP6",
        type: "Registration for all interested users",
        price: "$7.10",
      },
      {
        ext: ".NP.CP6",
        type: "Registration for business users",
        price: "$4.10",
      },
      {
        ext: ".OPR.CP6",
        type: "Registration for business users",
        price: "$4.10",
      },
      {
        ext: ".O6P.CP6",
        type: "Registration for educational organizations",
        price: "$4.10",
      },
      {
        ext: ".OA.CP6",
        type: "Registration for individuals",
        price: "$4.10",
      },
    ],

    "International Domains": [
      {
        ext: ".COM",
        type: "Registration for all interested users",
        price: "$14.80",
      },
      {
        ext: ".NET",
        type: "Registration for all interested users",
        price: "$17.10",
      },
      {
        ext: ".ORG",
        type: "Registration for all interested users",
        price: "$17.50",
      },
      {
        ext: ".BIZ",
        type: "Registration for all interested users",
        price: "$21.70",
      },
      {
        ext: ".EDUCATION",
        type: "Registration for all interested users",
        price: "$22.20",
      },
      {
        ext: ".ME",
        type: "Registration for all interested users",
        price: "$26.10",
      },
      {
        ext: ".INFO",
        type: "Registration for all interested users",
        price: "$27.10",
      },
      {
        ext: ".XYZ",
        type: "Registration for all interested users",
        price: "$17.60",
      },
      {
        ext: ".TV, .CC, .FM, .AM i mnogi drugi",
        type: "Registration for all interested users",
        price: "call us",
        small: true,
      },
    ],

    "New Domains": [
      {
        ext: ".CAFE",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$39.40",
      },
      {
        ext: ".PRO",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$26.80",
      },
      {
        ext: ".COMPANY",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$25.60",
      },
      {
        ext: ".DENTAL",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$62.30",
      },
      {
        ext: ".DIGITAL",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$39.50",
      },
      {
        ext: ".DOCTOR",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$119.60",
      },
      {
        ext: ".CLOUD",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$24.30",
      },
      {
        ext: ".CLUB",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$12.50",
      },
      {
        ext: ".EDUCATION",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$19.30",
      },
      {
        ext: ".ONLINE",
        type: "Registracija za sve zainteresovane  korisnike",
        price: "$49.90",
      },
    ],
  };

  const faqList = [
    {
      question: "What is domain name?",
      answer: `A domain name, often just called a domain, functions much like a physical  home address. It serves as the means by which people locate your website on  the internet, either by typing the domain into a web browser or by using a  search engine. <br/> <br/>
Examples of domains include Google.com and Facebook.com. For best results, a domain should ideally be registered to match the name of the brand it represents.`,
      html: true,
    },
    {
      question: "Why do I need to buy a domain name? ",
      answer: `Purchasing domain names is crucial for enabling potential visitors to discover  your site. In the absence of specific website names, access would require  inputting the IP address, a method far less memorable.<br/> <br/>
When conducting a domain search for an apt name, incorporating keywords or  brand names can be beneficial.`,
      html: true,
    },
    {
      question: "How Do I Get a Free Domain?",
      answer: `Metoda provides the best way to get a great domain for free. Simply sign up for a Premium or Business web hosting plan for 12 months or more, and you'll receive a free domain registration for one year.`,
    },
    {
      question: "What Is Privacy Protection?",
      answer: `Privacy protection, also known as WHOIS protection, hides certain information about a  domain name's owner that would otherwise be accessible through a WHOIS lookup. <br/> <br/>
This protection allows the domain registrar to replace your name, address, phone  number, email address, and business name with generic, non-identifiable information.`,
      html: true,
    },
    {
      question: "How to search for a perfect website name? ",
      answer: `Optimal strategies for acquiring domain names involve: <br/> Conciseness: Short names are best. Aim for two to three words <br/> Clarity: Avoid long or challenging-to-spell words. <br/> Relevance: Incorporate a keyword related to your field. For instance, <br/> belgradecoffeebeans.com for a coffee bean seller in Belgrade.<br/> Simplicity: Steer clear of numbers, as they complicate memorability. <br/> Branding: Choose a domain that features your brand name to enhance recognition.`,
      html: true,
    },
    {
      question: "How Long does a domain name registration last? ",
      answer: `Domain names can be registered for a minimum of one year. At Metoda, options to register domains such as .com, .net, .org, and others extend up to three years. <br />
To ensure your domains remain registered in your name and your website stays online continuously, setting up automatic renewal is advised.
`,
      html: true,
    },
    {
      question: "After my domain is registered, can I change it later? ",
      answer: `No, once you register a domain, it cannot be changed. However, you can purchase a new domain name and transfer your website to that domain. 
`,
    },
    {
      question: "What’s the Difference Between a Domain Name and Web Hosting?",
      answer: `Think of your domain name as your home address and web hosting as the physical  structure, like the house. A domain helps people find your site, while hosting provides  the online space to store your website data. To publish a website, you'll need both  domain and hosting services so that visitors can easily find your site on the internet.
<br/> <br/> While it’s technically possible to have one without the other, they work best together.  This is why many hosting companies offer domain names, and many domain registrars provide hosting services. `,
      html: true,
    },
    {
      question: "How can you check if a domain name is available? ",
      answer: `Enter your desired name into the domain name search tool to check its  availability.
<br/> <br/>If it's available, you can add it to your cart and purchase the domain. If it's  already taken, we will suggest other great options for you.`,
      html: true,
    },
    {
      question: "What are the requirements for buying domain names at Metoda? ",
      answer: `Checking domain availability is free, and there are no special requirements for  purchasing a domain name. We only need basic contact information and a valid payment method.
<br/> <br/> You can also buy domains from us and point them to another hosting provider.`,
      html: true,
    },
    {
      question: "How long does it take to register a domain name? ",
      answer: `Metoda offers instant activation, ensuring your desired domain name is ready to use immediately.
<br/> <br/> We've streamlined the process, so it takes just a few minutes to go from  checking domain name availability to successfully registering it.`,
      html: true,
    },
    {
      question: "Where can I buy a domain? ",
      answer: `A range of trusted domain registrars, Metoda included, are available for purchasing a  domain. Selecting a registrar known for excellent customer service and attractive  pricing is crucial. Acquiring a domain marks the beginning of building a robust online identity, making it essential to make an informed choice!`,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="domain">
      {/* <section className="hero" style={{ paddingBottom: 0 }}>
        <div className="hero__header">
          <img
            src={headerBg}
            alt=""
            style={{ width: "80vw", maxWidth: "450px" }}
          />
          <h1 className="hero__header__title">
            <span>Domain Registration</span>
          </h1>
        </div>

        <p
          className="hero__disc"
          style={{
            maxWidth: 500,
            marginBottom: 0,
            position: "relative",
            zIndex: 2,
          }}
        >
          Secure, manage, and optimize your online identity with ease using our
          comprehensive domain services
        </p>

        <img
          src={hero}
          className="hero__img"
          alt=""
          style={{ position: "relative", top: "-50px" }}
        />
      </section> */}
      <section className="domain__search">
        <h1 className="hero__header__title">
          <span>Domain Registration</span>
        </h1>

        <div className="faq__search">
          <img src={search} alt="" />
          <div className="faq__search__input">
            <input type="text" placeholder="Enter your desired domain" />
          </div>
          <button>
            <span>Search</span>
          </button>
        </div>

        <div className="domain__plan__main">
          <h3>Choose From the Most Popular Domains</h3>

          <div className="domain__plan__main__list">
            <Swiper
              spaceBetween={10}
              slidesPerView={4}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              breakpoints={{
                320: { slidesPerView: 2, spaceBetween: 10 },
                480: { slidesPerView: 2, spaceBetween: 10 },
                768: { slidesPerView: 3, spaceBetween: 10 },
                980: { slidesPerView: 4, spaceBetween: 10 },
              }}
              modules={[Navigation]}
              onActiveIndexChange={(e) => {
                if (e.isBeginning) {
                  setHide("left");
                } else if (e.isEnd) {
                  setHide("right");
                } else {
                  setHide("none");
                }
              }}
            >
              {planList.map((item) => {
                return (
                  <SwiperSlide key={item.name}>
                    <div className="domain__plan__main__list__item">
                      <h4>{item.name}</h4>

                      <div className="domain__plan__main__list__item__price">
                        <p>
                          $<b>{item.price}</b>
                        </p>
                        <span>for the 1st year</span>
                      </div>

                      <button className="btn">
                        <span>Get Started</span>
                      </button>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="domain__plan__main__list__nav">
              {hide === "none" ? (
                <>
                  <button
                    className="left"
                    onClick={() => swiperRef.current?.slidePrev()}
                  >
                    <img src={arrowLeftD} alt="" />
                  </button>
                  <button onClick={() => swiperRef.current?.slideNext()}>
                    <img src={arrowRightD} alt="" />
                  </button>
                </>
              ) : hide === "left" ? (
                <>
                  <div></div>
                  <button onClick={() => swiperRef.current?.slideNext()}>
                    <img src={arrowRightD} alt="" />
                  </button>
                </>
              ) : (
                <>
                  <button onClick={() => swiperRef.current?.slidePrev()}>
                    <img src={arrowLeftD} alt="" />
                  </button>
                  <div></div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="domain__cat">
        <div className="domain__cat__header">
          {catHeaders.map((item) => {
            return (
              <div
                className={
                  item.name === selectedCatHeader
                    ? "domain__cat__header__item alt"
                    : "domain__cat__header__item"
                }
                onClick={() => setSelectedCatHeader(item.name)}
              >
                <b>{item.name}</b>
                <span>{item.shtName}</span>
                {item.name === selectedCatHeader && <p>{item.types}</p>}
              </div>
            );
          })}
        </div>

        <div className="domain__cat__title">
          <b>{selectedCatHeader}</b>
          <div className="domain__cat__title__right">
            Sve cene su izrazene bez PDV-a. <span>Prikazi cene sa PDV-om.</span>
          </div>
        </div>

        <div style={{ overflow: "auto", width: "100%", maxWidth: 1000 }}>
          <div className="domain__cat__list">
            <div className="domain__cat__list__top">
              <div
                className="domain__cat__list__top__item"
                style={{
                  width:
                    selectedCatHeader === "International Domains"
                      ? "240px"
                      : "140px",
                }}
              >
                Extension
              </div>
              <div
                className="domain__cat__list__top__item"
                style={{ width: "300px" }}
              >
                Type of User
              </div>
              <div
                className="domain__cat__list__top__item"
                style={{ width: "130px" }}
              >
                Price (yearly)
              </div>
            </div>

            <div className="domain__cat__list__main">
              {tableData[selectedCatHeader]?.map((item) => {
                return (
                  <div className="domain__cat__list__main__item">
                    <b
                      style={{
                        width:
                          selectedCatHeader === "International Domains"
                            ? "200px"
                            : "100px",
                      }}
                    >
                      {item.ext}
                    </b>

                    <span style={{ width: "300px" }}>{item.type}</span>
                    {item?.newPrice ? (
                      <div
                        style={{ width: "150px" }}
                        className="domain__cat__list__main__item__new"
                      >
                        <p>
                          {item.price}
                          <span></span>
                        </p>
                        <b>{item.newPrice}</b>
                        <img src={important} alt="" />
                      </div>
                    ) : (
                      <b style={{ width: "120px" }}>{item.price}</b>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="domain__plan__main__list mobile">
          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            onBeforeInit={(swiper) => {
              swiperRef2.current = swiper;
            }}
            breakpoints={{
              320: { slidesPerView: 2, spaceBetween: 10 },
              480: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 3, spaceBetween: 10 },
              980: { slidesPerView: 4, spaceBetween: 10 },
            }}
            modules={[Navigation, Pagination]}
            pagination={true}
            onActiveIndexChange={(e) => {
              if (e.isBeginning) {
                setHide2("left");
              } else if (e.isEnd) {
                setHide2("right");
              } else {
                setHide2("none");
              }
            }}
            style={{ paddingBottom: 45 }}
          >
            {tableData[selectedCatHeader]?.map((item) => {
              return (
                <SwiperSlide key={item.ext} style={{ height: "auto" }}>
                  <div
                    className={
                      item?.newPrice
                        ? "domain__plan__main__list__item alt"
                        : "domain__plan__main__list__item"
                    }
                    style={{ height: "100%", justifyContent: "center" }}
                  >
                    <h4
                      className={
                        item?.small
                          ? "domain__plan__main__list__item__title small"
                          : "domain__plan__main__list__item__title"
                      }
                    >
                      {item.ext}
                    </h4>

                    <div className="domain__plan__main__list__item__price">
                      {item?.newPrice ? (
                        <>
                          <div
                            style={{ justifyContent: "center" }}
                            className="domain__cat__list__main__item__new"
                          >
                            <p>
                              {item.price}
                              <span></span>
                            </p>
                          </div>
                          <p>
                            <b>{item.newPrice}</b>
                          </p>
                        </>
                      ) : (
                        <p>
                          <b>{item.price}</b>
                        </p>
                      )}
                      <span
                        style={{
                          fontSize: 10,
                          lineHeight: "1.2",
                          display: "inline-block",
                          marginTop: 5,
                        }}
                      >
                        {item.type}
                      </span>
                    </div>

                    <button className="btn">
                      <span>Get Started</span>
                    </button>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="domain__plan__main__list__nav alt">
            {hide2 === "none" ? (
              <>
                <button
                  className="left"
                  onClick={() => swiperRef2.current?.slidePrev()}
                >
                  <img src={arrowLeftD} alt="" />
                </button>
                <button onClick={() => swiperRef2.current?.slideNext()}>
                  <img src={arrowRightD} alt="" />
                </button>
              </>
            ) : hide2 === "left" ? (
              <>
                <div></div>
                <button onClick={() => swiperRef2.current?.slideNext()}>
                  <img src={arrowRightD} alt="" />
                </button>
              </>
            ) : (
              <>
                <button onClick={() => swiperRef2.current?.slidePrev()}>
                  <img src={arrowLeftD} alt="" />
                </button>
                <div></div>
              </>
            )}
          </div>
        </div>
      </section>

      {/* <div className="domain__con"> */}
      <section className="domain__why">
        <h2 className="whyWork__main__title" style={{ letterSpacing: "-1px" }}>
          <span>Why</span> choose <span>Metoda</span> for <span>domain</span>{" "}
          names
        </h2>

        <p className="domain__why__disc">
          Metoda delivers dependable domain registration with immediate
          activation, 24/7 dedicated support, and comprehensive DNS control
        </p>

        <div className="domain__why__list">
          {whyList?.map((item) => {
            return (
              <div className="domain__why__list__item" key={item.title}>
                <img src={item.icon} alt="" />
                <div className="domain__why__list__item__main">
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* </div> */}
      <section className="domain__ess">
        <h2 className="whyWork__main__title" style={{ letterSpacing: "-1px" }}>
          <span>Essentials</span> Before Acquiring <span>Domain</span> Names
        </h2>

        <div className="domain__ess__list">
          {essList.map((item, i) => {
            const num = 1 + i;
            const bottomRow = i > 2 ? true : false;
            if (num % 2 === 0) {
              return (
                <div
                  className={
                    bottomRow
                      ? "domain__ess__list__item btm"
                      : "domain__ess__list__item"
                  }
                  key={item.title}
                >
                  <span>{i + 1}</span>
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              );
            } else {
              return (
                <div
                  className={
                    i === 4
                      ? "domain__ess__list__item btm altB"
                      : bottomRow
                      ? "domain__ess__list__item alt btm"
                      : "domain__ess__list__item alt"
                  }
                  key={item.title}
                >
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                  <span>{i + 1}</span>
                </div>
              );
            }
          })}
        </div>

        <div className="domain__ess__list__mobile">
          <img src={essMobile} alt="" />
        </div>
      </section>

      <Faq faqList={faqList} alt2={true} />
    </div>
  );
};

export default Domain;
