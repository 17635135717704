import React, { useEffect } from "react";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import heroBgD from "../assets/images/svg/heroBgD.svg";
import dedicatedY1 from "../assets/images/svg/dedicatedY1.svg";
import dedicatedY2 from "../assets/images/svg/dedicatedY2.svg";
import dedicatedY3 from "../assets/images/svg/dedicatedY3.svg";
import dedicatedF1 from "../assets/images/svg/dedicatedF1.svg";
import dedicatedF2 from "../assets/images/svg/dedicatedF2.svg";
import dedicatedF3 from "../assets/images/svg/dedicatedF3.svg";
import dedicatedF4 from "../assets/images/svg/dedicatedF4.svg";
import dedicatedF5 from "../assets/images/svg/dedicatedF5.svg";
import dedicatedF6 from "../assets/images/svg/dedicatedF6.svg";
import dedicatedF7 from "../assets/images/svg/dedicatedF7.svg";
import dedicatedF8 from "../assets/images/treeIcon4.png";

import Faq from "../components/Faq";
import Plan from "../components/Plan";
import { HashLink } from "react-router-hash-link";

const Dedicated = () => {
  const youList = [
    {
      icon: dedicatedY1,
      title: "Local availability and lower latency",
      disc: "A dedicated server offers faster connection and lower latency for users in the region. This is especially important for websites and applications targeting users in Serbia or nearby countries.",
    },
    {
      icon: dedicatedY2,
      title: "Full control over the server",
      disc: "The user has complete control over the resources and configurations, without sharing them with others..",
    },
    {
      html: true,
      icon: dedicatedY3,
      title: `Increased security and reliability`,
      disc: "A dedicated server provides a higher level of security because you are not sharing resources with other users, reducing the risk of attacks and poor performance due to system overload.",
    },
  ];

  const featureList = [
    {
      icon: dedicatedF2,
      title: "Scalability",
      disc: "Our servers are crafted from the ground up using cutting-edge open source technology.",
    },
    {
      icon: dedicatedF4,
      title: "24/7 technical support",
      disc: "Enhance your server's storage capacity instantly and in real time, without the need for server administrator.",
    },
    {
      icon: dedicatedF7,
      title: "Security add-ons",
      disc: "Our expert team tailors, assembles, and connects your server to the network in a swift timeframe of 24-72 hours.",
    },
    {
      icon: dedicatedF1,
      title: "High-quality network connectivity and speed",
      disc: "We're pretty sure this won't happen, but if it doesn't tickle your fancy,we'll gladly give your money back!",
    },
    {
      icon: dedicatedF8,
      title: "Data backup and redundancy",
      disc: "For the advanced user, optional full root access grants complete freedom for custom installations and beyond. ",
    },
    {
      icon: dedicatedF6,
      title: "Full control over the server",
      disc: "Our premium storage setup employs RAID1 to mirror your data, enhancing both its security and reliability.  ",
    },
  ];

  const planList = [
    {
      name: "Standard",
      disc: "Enjoy a fully dedicated server all to yourself",
      price: "109",
      per: "/month",
      features: [
        "4 core / 8 thread",
        "Intel Xeon-D CPU",
        "8 GB RAM",
        "1 TB HDD storage",
        "Unmetered Bandwidth",
        "Linux",
        "Cpanel included with Root access",
      ],
    },
    {
      name: "Advanced",
      disc: "More capacity. Advanced performance",
      price: "165",
      per: "/year",
      features: [
        "8 core / 16 thread",
        "Intel Xeon-D CPU",
        "16 GB RAM",
        "2 TB HDD storage",
        "Unmetered bandwidth ",
        "Linux",
        "Cpanel included with Root access",
      ],
      alt: true,
    },
    {
      name: "Premium",
      disc: "Optimal for the highest performing websites.",
      price: "236",
      per: "/month",
      features: [
        "8 core / 16 thread",
        "Intel Xeon-D CPU",
        "30 GB RAM",
        "2 TB HDD storage",
        "Unmetered bandwidth ",
        "Linux",
        "Cpanel included with Root access",
      ],
    },
  ];

  const faqList = [
    {
      question: "What is Dedicated Hosting?",
      answer: `Offers the pinnacle in resource allocation, privacy, and control within a website hosting framework. In this environment, Dedicated servers operate in complete isolation, affording users the autonomy to configure their server as they see fit without interference from or to others. `,
    },
    {
      question: "What are the advantages of Dedicated Hosting?",
      answer: `The main advantages of Dedicated hosting include unparalleled control, privacy, and assured resources. Dedicated servers are exclusively reserved, ensuring that the performance of a customer's website remains consistent and predictable. This hosting option is ideally suited for websites with high traffic, where stability is paramount.`,
    },
    {
      question: "How can I start with Dedicated Server?",
      answer: `Starting with Dedicated server is straightforward. Just select from our Standard, Advanced, or Premium Dedicated server plans, depending on your website's resource requirements, and proceed with the sign-up directions.`,
    },
    {
      question:
        "Where can I purchase a domain for my Dedicated Server Hosting account?",
      answer: `Purchase a domain name for your Dedicated Server Hosting account directly through us, Metoda. Explore available domains now and discover the ideal .com, .rs, .hr, .net, .ba to embody your business!`,
    },
    {
      question:
        "How many websites are able to be hosted on a Dedicated Server?",
      answer: `On a Dedicated Server, you have the freedom to host an unlimited number of websites, provided there are sufficient resources to support them. It's crucial to remember, though, that each website will consume a portion of your server's resources. Therefore, it's advisable to only host as many websites as your resources can comfortably accommodate without overextending.`,
    },
    {
      question:
        "Is it possible to obtain a professional email account with a Dedicated Server plan?",
      answer: `Absolutely! Securing a professional email account alongside a Dedicated Server plan is available. Many hosting services present a selection of email packages, allowing you to select one that aligns with your requirements and financial plan.`,
    },
    {
      question:
        "Does Dedicated Server Hosting come with a dedicated IP address?",
      answer: `Indeed, each Dedicated Server Hosting plan comes with a dedicated IP address, ensuring your website is assigned a unique address not shared with others. This exclusive IP address contributes to enhancing your website's security and performance. `,
    },
    {
      question:
        "What are the key differences between Dedicated Server and Shared Web Hosting?",
      answer: `The key distinction between Dedicated Server and Web Hosting lies in the level of control and resource allocation. Dedicated server offers exclusive access to a server, providing you with ample resources and the flexibility to tailor the server to your precise requirements. In contrast, Web Hosting involves sharing a server with multiple websites. Dedicated server not only affords greater reliability but also grants enhanced control over your website's functionality. `,
    },
    {
      question:
        "What is the difference between Dedicated Hosting and VPS (Virtual Private Server) Hosting? ",
      answer: `VPS hosting users enjoy dedicated resources similar to those in Dedicated hosting, but the resources provided by VPS servers might not meet all the needs of a website. For instance, VPS servers typically offer starting RAM of 2GB, while Dedicated servers generally begin with a minimum of 8GB of RAM. Essentially, Dedicated hosting offers all the customization and control capabilities of a VPS server, but with significantly more power.`,
    },
    {
      question: "How many cPanel accounts are included with this service? ",
      answer: `The quantity of cPanel accounts available to you varies based on the Dedicated Server Hosting plan selected. Our Standard plans include 5 cPanel accounts, whereas the Premium plan provides 50 cPanel accounts.`,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="dedicated">
      <section className="hero">
        <div className="hero__header">
          <img
            src={headerBg}
            alt=""
            style={{ width: "80vw", maxWidth: "800px" }}
          />
          <h1 className="hero__header__title">
            <span>Dedicated</span>
            <div>Server</div>
          </h1>
        </div>

        <p className="hero__disc" style={{ maxWidth: "450px" }}>
          Hosting for Your Business - Maximum Performance and Security. Your
          servers, your resources – no sharing with others.
        </p>

        <div className="hero__action" style={{ marginBottom: 10 }}>
          <HashLink to="#pricing">
            <button>
              <span>Get Started</span>
            </button>
          </HashLink>

          <p>from$2.99/mo*</p>
        </div>

        <img src={heroBgD} className="hero__img vpsHero" alt="" />
      </section>

      <div className="dedicated__bgBlur">
        <section className="dedicated__you">
          <h2 className="whyWork__main__title">
            <span>Dedicated</span> server - <span>Dedicated</span> for{" "}
            <span>you</span>
          </h2>

          <p className="dedicated__you__disc">
            We offer dedicated server hosting solutions tailored to meet the
            demanding requirements of businesses seeking ultimate performance,
            security, and control.
          </p>

          <div className="dedicated__you__list">
            {youList.map((item, i) => {
              return (
                <div
                  className={
                    i === 2
                      ? "dedicated__you__list__item last"
                      : "dedicated__you__list__item"
                  }
                  key={item.title}
                >
                  <img src={item.icon} alt="" />

                  {item.html ? (
                    <h4 dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                  ) : (
                    <h4>{item.title}</h4>
                  )}
                  <p>{item.disc}</p>
                </div>
              );
            })}
          </div>
        </section>

        <section className="dedicated__features">
          <h2 className="whyWork__main__title">
            <span>Key features</span>
          </h2>

          <p className="dedicated__you__disc">
            With a relentless focus on innovation, quality, and client
            satisfaction, we offer a unique blend of expertise, creativity, and
            collaborative spirit that sets us apart.
          </p>

          <div className="dedicated__features__list">
            {featureList.map((item) => {
              return (
                <div
                  className="dedicated__features__list__item"
                  key={item.title}
                >
                  <img src={item.icon} alt="" />
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              );
            })}
          </div>

          <button className="dedicated__features__btn">
            <span>Get Started</span>
          </button>
        </section>
      </div>

      <Plan planList={planList} alt={true} />

      <Faq faqList={faqList} alt={true} />
    </div>
  );
};

export default Dedicated;
