import React from "react";
import globe from "../assets/images/globe.png";
import headerBg from "../assets/images/svg/headerBg.svg";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <section className="hero">
      <div className="hero__header">
        <img src={headerBg} alt="" />
        <h1 className="hero__header__title">
          <span>{t("heroTitle1")}</span>
          <div>{t("heroTitle2")}</div>
          <div>{t("heroTitle3")}</div>
        </h1>
      </div>
      <p className="hero__disc">{t("heroDisc")}</p>
      <div className="hero__action">
        <HashLink to="#pricing">
          <button>
            <span>Get Started</span>
          </button>
        </HashLink>
        <p>from$2.99/mo*</p>
      </div>

      <img className="hero__img" src={globe} alt="" />
    </section>
  );
};

export default Hero;
