import hero from "../assets/images/hostingHero.png";
import shared from "../assets/images/sharedHero.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import heroVps from "../assets/images/svg/heroVps.bg.svg";
import heroBgD from "../assets/images/svg/heroBgD.svg";
import lockIcon from "../assets/images/svg/Lock.svg";
import { HashLink } from "react-router-hash-link";
import Plan from "../components/Plan";
import Faq from "../components/Faq";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Hosting = () => {
  const lifeList = [
    {
      title: "Shared Hosting",
      disc: "A type of web hosting where a single physical server hosts multiple sites.",
      img: shared,
      url: "/services/shared",
    },
    {
      title: "Virtual Private Server (VPS)",
      disc: "Acts as an isolated, virtual environment on a physical server, which is  owned and operated by a cloud or web hosting provider.",
      img: heroVps,
      url: "/services/vps",
    },
    {
      title: "Dedicated Servers",
      disc: "An internet hosting option in which a physical server (or servers) is dedicated to a single business customer.",
      img: heroBgD,
      url: "/services/dedicated-server",
    },
  ];

  const performanceList = [
    {
      title: "Lightning-Fast Speed",
      disc: "Enjoy super-fast website loading times thanks to SSD storage and advanced caching technologies, with servers optimized for popular platforms like WordPress.",
    },
    {
      title: "24/7 Expert Support",
      disc: "Get the help you need, whenever you need it, with 24/7 customer support via phone, email, or live chat.",
    },
    {
      title: "99.9% Uptime Guarantee",
      disc: "Your website will be online and accessible at all times, with a guaranteed 99.9% uptime, minimizing any downtime.",
    },
    {
      title: "Flexible and Scalable Solutions",
      disc: "Easily scale your resources as your website grows, without the hassle of changing servers or plans. Today",
    },
  ];

  const planList = [
    {
      name: "Shared Hosting",
      disc: "Enjoy affordable rates while still receiving top-notch service.",
      price: "2.99",
      per: "/month",
      features: [
        "*Core / *Thread",
        "Intel Xeon-D CPU",
        "8 GB RAM",
        "1 TB HDD storage",
        "Unmetered Bandwidth",
        "Linux",
        "Cpanel included with Root access",
      ],
      link: "/services/shared#pricing",
    },
    {
      name: "VPS Hosting",
      disc: "Launch your own virtual server today. ",
      price: "18",
      per: "/year",
      features: [
        "2 GB RAM",
        "2 core CPU",
        "120 GB SSD",
        "Unmetered Bandwidth",
        "Cpanel included with Root access",
      ],
      alt: true,
      link: "/services/vps#pricing",
    },
    {
      name: "Dedicated Server",
      disc: "Enjoy a fully dedicated server all for yourself. ",
      price: "127",
      per: "/month",
      features: [
        "4 core / 8 thread",
        "Intel Xeon-D CPU",
        "8 GB RAM",
        "1 TB HDD storage",
        "Unmetered Bandwidth",
        "Linux",
        "Cpanel included with Root access",
      ],
      link: "/services/dedicated-server#pricing",
    },
  ];

  const faqList = [
    {
      question: "What is web hosting?",
      answer: `Web hosting is a service that allows individuals and organizations to publish their websites on the internet. Hosting providers offer the technology and services needed to make a site accessible online.`,
    },
    {
      question: "What types of hosting do you offer?",
      answer: `We offer various types of hosting, including shared hosting, VPS hosting, dedicated server hosting to meet the needs of different users.`,
    },
    {
      question: "How do I choose the right hosting plan?",
      answer: `Choosing the right plan depends on your specific needs, such as the size of your website, expected traffic, and technical requirements. Our team can help you find the best option.`,
    },
    {
      question: "How can I migrate my existing site to your server?",
      answer: `Our team can assist you with migrating your site. You can contact us, and we will provide all the necessary information and support during the process.`,
    },
    {
      question: "What happens if my site goes down?",
      answer: `If you experience issues, our support team is available 24/7 to help you. We also regularly back up your data to ensure we can quickly restore it.`,
    },
    {
      question: "Do you have customer support?",
      answer: `Yes, we provide 24/7 customer support via email, phone, and live chat. We are always here to answer your questions and assist you.`,
    },
    {
      question: "How can I cancel my hosting account?",
      answer: `To cancel your hosting account, you can contact our customer support or do so through your account on our website. We will guide you through the necessary steps.`,
    },
    {
      question: "Can I get professional email hosting?",
      answer: `Yes, Metoda offers professional email hosting services. This service can be seamlessly  integrated with various web hosting packages. Opting for professional email hosting  allows users to create a custom email address using their own domain name,  enhancing the professional appearance of their email communications.  `,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="hosting">
      <section className="hero">
        <div className="hero__header">
          <img
            src={headerBg}
            alt=""
            style={{ width: "80vw", maxWidth: "800px" }}
          />
          <h1 className="hero__header__title">
            <span>Hosting Services </span>
          </h1>
        </div>

        <p className="hero__disc" style={{ maxWidth: 550 }}>
          Secure, manage, and optimize your online identity with ease using our
          comprehensive hosting services
        </p>

        <div className="hero__action">
          <HashLink to="#pricing">
            <button>
              <span>Get Started</span>
            </button>
          </HashLink>

          <p>from$2.99/mo*</p>
        </div>

        <img
          src={hero}
          className="hero__img vpsHero"
          alt=""
          style={{ marginTop: 27 }}
        />
      </section>

      <section className="hosting__life">
        <h2 className="whyWork__main__title">
          <span>Breathe Life Into Your Website with Our:</span>
        </h2>

        <div className="hosting__life__list">
          {lifeList.map((item) => {
            return (
              <Link to={item.url} style={{ height: "100%" }}>
                <div
                  className="hosting__life__list__item"
                  style={{ height: "100%" }}
                  key={item.title}
                >
                  <img src={item.img} alt="" />
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              </Link>
            );
          })}
        </div>
      </section>

      <section className="hosting__performance">
        <h2 className="whyWork__main__title">
          <span>Performance:</span>
        </h2>

        <div className="hosting__performance__list">
          {performanceList.map((item) => {
            return (
              <div
                key={item.title}
                className="hosting__performance__list__item"
              >
                <h4>{item.title}</h4>
                <p>{item.disc}</p>
              </div>
            );
          })}
        </div>
      </section>

      <section className="hosting__security">
        <h2 className="whyWork__main__title">
          <span>Security Features:</span>
        </h2>

        <div className="hosting__security__list">
          <div className="hosting__security__list__item">
            <img src={lockIcon} alt="" />

            <p>
              Ensure secure data transmission and build trust with your visitors
              through <span>SSL certificates</span>, enhancing both security and
              SEO.
            </p>
          </div>

          <div className="hosting__security__list__item">
            <img src={lockIcon} alt="" />

            <p>
              Safeguard your website against malicious traffic with built-in{" "}
              <span>DDoS protection</span>, keeping your site accessible and
              reliable.
            </p>
          </div>

          <div className="hosting__security__list__item">
            <img src={lockIcon} alt="" />

            <p>
              Keep your data safe with <span>regular automated backups</span>,
              ensuring quick recovery in case of any issues.
            </p>
          </div>
          <div className="hosting__security__list__item">
            <img src={lockIcon} alt="" />

            <p>
              Protect your website with basic{" "}
              <span>malware scanning and removal</span> to prevent potential
              security threats and maintain your site's integrity.
            </p>
          </div>
          <div className="hosting__security__list__item">
            <img src={lockIcon} alt="" />

            <p>
              Defend your website from unauthorized access with{" "}
              <span>firewall protections</span> that monitors and filters
              incoming traffic
            </p>
          </div>
        </div>
      </section>

      <Plan planList={planList} hosting={true} />

      <Faq faqList={faqList} />
    </div>
  );
};

export default Hosting;
