import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import sharedHero from "../assets/images/sharedHero.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import sharedR1 from "../assets/images/svg/sharedR1.svg";
import sharedR2 from "../assets/images/svg/sharedR2.svg";
import sharedR3 from "../assets/images/svg/sharedR3.svg";
import sharedF1 from "../assets/images/svg/sharedF1.svg";
import sharedF2 from "../assets/images/svg/sharedF2.svg";
import sharedF3 from "../assets/images/svg/sharedF3.svg";
import sharedF4 from "../assets/images/svg/sharedF4.svg";
import sharedF5 from "../assets/images/svg/sharedF5.svg";
import Plan from "../components/Plan";
import Faq from "../components/Faq";

const Shared = () => {
  const [feature, setFeature] = useState({
    icon: sharedF4,
    title: "cPanel",
    disc: "Our hosting plans come with dedicated expert management, ensuring your website runs smoothly and securely at all times. We handle updates and maintenance, so you can stay focused on your business.",
  });

  const reasonList = [
    {
      icon: sharedR1,
      title: "Affordable",
      disc: "Shared hosting is the most cost-effective option because multiple users share the resources of a single server. it is ideal for beginners and small businesses.",
    },
    {
      icon: sharedR2,
      title: "Easy to Use",
      disc: "Shared hosting plans come with user-friendly control panel cPanel, which make managing your website easy without needing technical knowledge",
    },
    {
      icon: sharedR3,
      title: "Maintenance Included",
      disc: "We take care of server maintenance, security updates, and technical support, allowing you to focus on developing and managing your website’s content.",
    },
  ];

  const featureList = [
    {
      icon: sharedF4,
      title: "cPanel",
      disc: "Our hosting plans come with dedicated expert management, ensuring your website runs smoothly and securely at all times. We handle updates and maintenance, so you can stay focused on your business.",
    },
    {
      icon: sharedF2,
      title: "Enhanced Security",
      disc: " Simplify your hosting experience by managing multiple domains under a single account. Ideal for growing businesses or managing several projects from one centralized platform.",
    },
    {
      icon: sharedF5,
      title: "Personalized Email Service",
      disc: "Create custom email addresses that match your domain, giving your business a professional image. Easily manage your emails through a user-friendly control panel.",
    },
    {
      icon: sharedF1,
      title: "Professionally Managed",
      disc: "Our team of experts ensures that your hosting environment is always optimized and secure. Let us handle the technical details while you focus on growing your business.",
    },
    {
      icon: sharedF3,
      title: "Host Multiple Domains",
      disc: "Manage several domains from a single hosting account, simplifying your website management. This is ideal for businesses with multiple sites or projects under one roof.",
    },
  ];

  const planList = [
    {
      name: "Standard",
      disc: "Experience the excellence of our services with a handful of small projects.",
      price: "1.68",
      per: "/month",
      features: ["Domain name", "3 Websites", "20 GB SSD", "30 Mailboxes"],
    },
    {
      name: "Advanced",
      disc: "Experience the excellence of our services with a handful of small projects.",
      price: "2.68",
      per: "/month",
      features: [
        "Domain name",
        "Unlimited websites",
        "50 GB SSD",
        "Unlimited Mailboxes",
        "AutoBackup",
      ],
      // alt: true,
    },
    {
      name: "Premium",
      disc: "Experience the excellence of our services with a handful of small projects.",
      price: "4.48",
      per: "/month",
      features: [
        "Domain name",
        "Unlimited websites",
        "Umetered GB SSD",
        "Unlimited Mailboxes",
        "AutoBackup & Cloud Storage",
      ],
    },
  ];

  const faqList = [
    {
      question: "What is shared hosting and how does it work? ",
      answer: `Shared hosting means multiple users share the resources of one server, making it an affordable option for small to medium websites. `,
    },
    {
      question: "How many domains can I host on one shared hosting account?",
      answer: `Depending on the plan, you can host one or multiple domains. `,
    },
    {
      question:
        "How much storage and monthly bandwidth is included in the plan? ",
      answer: `Storage and bandwidth depend on the plan, but often range from 10GB or more, with some plans offering unlimited bandwidth. `,
    },
    {
      question: "Can I upgrade the plan later if I need more resources? ",
      answer: `Yes, you can easily upgrade to larger plans. `,
    },
    {
      question:
        "Does shared hosting support installing popular CMS platforms like WordPress or Joomla? ",
      answer: `Yes, most shared hosting plans support easy installation of WordPress, Joomla, and other CMS platforms. `,
    },
    {
      question:
        "What security measures are implemented on shared hosting servers? ",
      answer: `Typically offer includes daily backups, DDoS protection, and firewall security. `,
    },
    {
      question:
        "What are the performance levels (speed, uptime) of shared hosting servers? ",
      answer: `We guarantee 99.9% uptime and optimized servers for fast performance. `,
    },
    {
      question: "Is technical support available 24/7? ",
      answer: `Yes, we offer 24/7 support. `,
    },
    {
      question:
        "How are resource overuse issues (CPU, RAM) handled on a shared hosting account? ",
      answer: `We will notify you if you exceed resources, and you can upgrade your plan or optimize your site. `,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="shared">
      <div className="shared__blur">
        <section className="hero">
          <div className="hero__header">
            <img
              src={headerBg}
              alt=""
              style={{ width: "80vw", maxWidth: "800px" }}
            />
            <h1 className="hero__header__title">
              <span>Shared Hosting</span>
            </h1>
          </div>

          <p className="hero__disc">
            Affordable Hosting Plans with Fast and Reliable Security
          </p>
          <div className="hero__action">
            <HashLink to="#pricing">
              <button>
                <span>Get Started</span>
              </button>
            </HashLink>
            <p>from$2.99/mo*</p>
          </div>

          <img
            src={sharedHero}
            className="hero__img"
            alt=""
            // style={{ position: "relative", top: "-30px" }}
          />
        </section>
        <section className="shared__reason">
          <h2 className="whyWork__main__title" style={{ gap: "7px" }}>
            <span>Sharing</span> is Caring
          </h2>
          <p className="shared__reason__disc">
            Reasons why you should choose shared hosting:
          </p>

          <div className="shared__reason__list">
            {reasonList.map((item) => {
              return (
                <div key={item.title} className="shared__reason__list__item">
                  <img src={item.icon} alt="" />
                  <div className="shared__reason__list__item__right">
                    <h4>{item.title}</h4>
                    <p>{item.disc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        <section className="shared__feature">
          <h2 className="whyWork__main__title" style={{ gap: "7px" }}>
            <span>Key Features:</span>
          </h2>

          <div className="shared__feature__list">
            {featureList.map((item) => {
              return (
                <div
                  key={item.title}
                  className={
                    feature.title === item.title
                      ? "shared__feature__list__item alt"
                      : "shared__feature__list__item"
                  }
                  onClick={() => setFeature(item)}
                >
                  <img src={item.icon} alt="" />
                  <h4>{item.title}</h4>
                </div>
              );
            })}
          </div>

          <div className="shared__feature__main">
            <img src={feature.icon} alt="" />
            <div className="shared__feature__main__right">
              <h4>{feature.title}</h4>
              <p>{feature.disc}</p>
            </div>
          </div>
        </section>
      </div>

      <Plan planList={planList} alt={true} shared={true} />

      <Faq faqList={faqList} alt={true} />
    </div>
  );
};

export default Shared;
