import hero from "../assets/images/emailHero.png";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import emailMag1 from "../assets/images/svg/emailMag1.svg";
import emailMag2 from "../assets/images/svg/emailMag2.svg";
import emailMag3 from "../assets/images/svg/emailMag3.svg";
import emailF1 from "../assets/images/svg/emailF1.svg";
import emailF2 from "../assets/images/svg/emailF2.svg";
import emailF3 from "../assets/images/svg/emailF3.svg";
import emailF4 from "../assets/images/svg/emailF4.svg";
import emailF5 from "../assets/images/svg/emailF5.svg";
import emailF6 from "../assets/images/svg/emailF6.svg";
import Plan from "../components/Plan";
import Faq from "../components/Faq";
import eclipes from "../assets/images/arcWhy.png";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Email = () => {
  const mag = [
    {
      icon: emailMag2,
      title: "Enhance Credibility",
      disc: "Build trust with an email address that aligns with your domain.",
    },
    {
      icon: emailMag3,
      title: "Strengthen Connections",
      disc: "Easily access your email and files from any location",
    },
    {
      icon: emailMag1,
      title: "Email Protection",
      disc: "Enhance your online security with superior spam and virus defenses.",
    },
  ];

  const featurelist = [
    {
      title: "Support for POP3 and IMAP Protocolssss",
      disc: "Users can utilize standard email clients like Outlook thanks to support for POP3 and IMAP protocols.",
      img: emailF1,
    },
    {
      title: "Additional Email Aliases per Account",
      disc: `Each email account can have several aliases, allowing multiple email addresses to funnel into a single inbox`,
      img: emailF2,
    },
    {
      title: "Increased Storage Capacity",
      disc: `Upon request, mailbox size can be expanded within the allocated storage space for the chosen package.`,
      img: emailF3,
    },
    {
      title: "Webmail Access",
      disc: `Provides access to your email from any location via a web browser, without the need for additional software installations`,
      img: emailF6,
    },
    {
      title: "Reliability and Security",
      disc: `The service ensures high availability of emails, with antivirus and anti-spam protection, enhancing data security.`,
      img: emailF4,
    },
    {
      title: "No Need for Additional IT Investments ",
      disc: `The service is fully hosted on the Metoda mail system, eliminating the need for further IT infrastructure investments and personnel.`,
      img: emailF5,
    },
  ];

  const planList = [
    {
      name: "Small",
      disc: "Enjoy a fully dedicated server all to yourself",
      price: "4",
      per: "/year",
      features: [
        "1-5 email accounts included in the package",
        "250 MB storage space for email messages",
      ],
    },
    {
      name: "Medium",
      disc: "EMore capacity. Advanced performance",
      price: "9",
      per: "/year",
      features: [
        "6-20 email accounts included in the package",
        "1 GB storage space for email messages",
      ],
    },
    {
      name: "Large",
      disc: "Enjoy a fully dedicated server all to yourself",
      price: "23",
      per: "/year",
      features: [
        "20-50 email accounts included in the package",
        "2.5 GB storage space for email messages",
      ],
    },
    {
      name: "Enterprise",
      disc: "Enjoy a fully dedicated server all to yourself",
      price: "35",
      per: "/year",
      features: [
        "Over 50 email accounts included in the package",
        "5 GB storage space for email messages",
      ],
    },
  ];

  const faqList = [
    //     {
    //       question: "What is professional email address?",
    //       answer: `Typically, a professional email address includes your business domain and a recognizable name format, such as firstname.lastname@yourcompany.rs`,
    //     },
    //     {
    //       question: "How do I set up email signatures for my professional email?",
    //       answer: `Email platforms usually have settings that allow you to create a custom signature, which can include your contact information, logo, and social media links.
    // `,
    //     },
    //     {
    //       question:
    //         "What security features should I look for in a professional email service? ",
    //       answer: `Essential security features include two-factor authentication, encryption, and spam filters to protect your email communications.`,
    //     },
    //     {
    //       question:
    //         "How does having a professional email boost credibility and trust?",
    //       answer: `Using a professional email that aligns with your domain name boosts credibility and trust, signaling a serious and dedicated approach to your brand. Customers tend to interact more with businesses that present themselves professionally, and this professionalism includes email communications.`,
    //     },
    //     {
    //       question: "What does domain-specific email mean? ",
    //       answer: `Domain-specific email addresses incorporate your company's website domain into the email itself. For example, if your website is www.metoda.rs, a corresponding email address could be vlad@metoda.rs. This approach enhances your professional appearance, supports brand uniformity, and aids in brand recognition. It's advisable for anyone operating a small business or eCommerce site to use a domain-specific email address for these benefits.`,
    //     },
    //     {
    //       question: "Can one user have multiple email addresses?",
    //       answer: `Indeed, within both Professional Email and Professional Email Plus hosting plans, a user can set up multiple email addresses or "aliases," useful for various roles or departments in your organization. For example, emails like sales@metoda.rs, support@metoda.rs, and alex@metoda.rs can all converge into a single inbox. Our Pro Email plans allow for the creation of up to 50 distinct aliases.`,
    //     },
    //     {
    //       question:
    //         "Will I be able to read and respond to emails using my mobile phone or tablet?",
    //       answer: `Definitely. Pro Email offers seamless synchronization across various devices, enabling you to access, read, and reply to emails from not just your computer but also your smartphone or tablet. This capability allows you to handle your email correspondence from any location, maintaining uninterrupted business operations. With an internet connection, your professional business email account is always within reach. `,
    //     },
    //     {
    //       question:
    //         "What are the main differences between Professional Email and Professional Email Plus? ",
    //       answer: `Both options come equipped with an extensive array of functionalities tailored to your business email requirements. Nonetheless, Professional Email Plus distinguishes itself by offering extra storage (80GB compared to 25GB), a higher number of email aliases (50 as opposed to 25), and the convenience of one-click email and file encryption. These added benefits afford greater versatility and improved security, making them especially advantageous for bigger teams or enterprises dealing with confidential data.  `,
    //     },
    {
      question: "How can I create a professional email address with Metoda?",
      answer:
        "To create a professional email address, visit the Metoda website, sign up for the service, and follow the registration steps. You will select your desired email address and choose a service package that suits your needs.",
    },
    {
      question: "What are the prices for professional email with Metoda?",
      answer:
        "Prices vary depending on the service package. Metoda offers different options based on storage space, features, and additional services like calendar integration and cloud storage.",
    },
    {
      question:
        "What functionalities does the Metoda professional email offer?",
      answer:
        "Metoda professional email includes access via the web, mobile app, and desktop applications. Additional functionalities include a calendar, tasks, cloud storage, and collaborative tools for teamwork.",
    },
    {
      question:
        "Does Metoda provide the option for a custom email address with my own domain?",
      answer:
        "Yes, Metoda allows the creation of email addresses with your personal or business domain (e.g.,name@company.com), which is available in the advanced packages.",
    },
    {
      question:
        "How can I access my Metoda email from a mobile phone or tablet?",
      answer:
        "You can use the Metoda mobile app or configure your email client on your device using the IMAP/SMTP settings provided during account activation.",
    },
    {
      question: "What is the storage capacity for emails with Metoda?",
      answer:
        "The storage capacity depends on the selected package. Basic packages typically offer smaller storage (e.g., 500 MB), while advanced packages provide more, up to 5 GB or more.",
    },
    {
      question:
        "How can I solve issues with not receiving or sending emails on my Metoda account?",
      answer:
        "Check the IMAP/SMTP settings in your email client or access your email through the web. If the issue persists, contact Metoda technical support for assistance.",
    },
    {
      question: "Does Metoda offer support for spam and virus filtering?",
      answer:
        "Yes, Metoda provides advanced spam and virus protection through filters that block unwanted emails and safeguard your email communication.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="email">
      <section className="hero" style={{ paddingBottom: 0 }}>
        <div className="hero__header">
          <img
            src={headerBg}
            alt=""
            style={{ width: "80vw", maxWidth: "800px" }}
          />
          <h1 className="hero__header__title">
            <span>Professional Email </span>
            <div>Services</div>
          </h1>
        </div>

        <p className="hero__disc" style={{ maxWidth: 500 }}>
          Opting for branded email is more than just selecting unique addresses.
          It’s about enhancing the experience for your entire team.
        </p>
        <div className="hero__action">
          <HashLink to="#pricing">
            <button>
              <span>Get Started</span>
            </button>
          </HashLink>
          <p>from$2.99/mo*</p>
        </div>

        <img
          src={hero}
          className="hero__img"
          alt=""
          // style={{ position: "relative", top: "-30px" }}
        />
      </section>

      <section className="email__mag">
        <h2 className="whyWork__main__title" style={{ letterSpacing: "-1px" }}>
          <span>Magnify</span> your <span>email experience</span>
        </h2>

        <p className="email__mag__disc">
          Enhance trust among your customers with affordable, professional
          email. Seamlessly sync your calendar, contacts, and other essentials.
          Bring your team together with email addresses specific to your domain
          and enhanced functionalities.
        </p>
        <div className="email__mag__main">
          {mag.map((item) => {
            return (
              <div className="email__mag__main__item" key={item.title}>
                <img src={item.icon} alt="" />
                <div className="email__mag__main__item__text">
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section
        className="whyWork alt"
        // style={{ paddingTop: 0, paddingBottom: "200px" }}
      >
        <div className="whyWork__main">
          <h2 className="whyWork__main__title">
            <span>Key features</span>
          </h2>

          <div className="whyWork__main__arc">
            <img src={eclipes} className="whyWork__main__arc__img alt" alt="" />
            <p className="whyWork__main__disc alt">
              With a relentless focus on innovation, quality, and client
              satisfaction, we offer a unique blend of expertise, creativity,
              and collaborative spirit that sets us apart.
            </p>

            <div className="whyWork__main__list alt">
              {featurelist.map((item, i) => {
                return (
                  <div key={item.title} className="whyWork__main__list__item">
                    <img
                      src={item.img}
                      alt=""
                      className={i === 3 ? "altImg small" : "altImg"}
                    />
                    <h4>{item.title}</h4>
                    <p className="alt">{item.disc}</p>
                  </div>
                );
              })}
            </div>
            <div className="whyWork__main__list__mobile">
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                pagination={true}
                loop={true}
                modules={[Pagination, Navigation]}
                style={{ paddingBottom: 30 }}
                navigation={true}
              >
                {featurelist.map((item, i) => {
                  return (
                    <SwiperSlide>
                      <div
                        key={item.title}
                        style={{ width: "70%", margin: "0 auto" }}
                        className="whyWork__main__list__item"
                      >
                        <img
                          src={item.img}
                          alt=""
                          className={i === 3 ? "altImg small" : "altImg"}
                        />
                        <h4>{item.title}</h4>
                        <p className="alt">{item.disc}</p>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="whyWork__main__btn">
              <button>
                <span>Get Started</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Plan planList={planList} email={true} alt={true} />

      <Faq faqList={faqList} alt={true} />
    </div>
  );
};

export default Email;
