import React, { useEffect } from "react";
import treeIcon1 from "../assets/images/svg/treeIcon1.svg";
import treeIcon2 from "../assets/images/svg/treeIcon2.svg";
// import treeIcon3 from "../assets/images/svg/treeIcon3.svg";
import treeIcon4 from "../assets/images/treeIcon4.png";
import heroVps from "../assets/images/svg/heroVps.bg.svg";
import headerBg from "../assets/images/svg/headerBgAlt.svg";
import Plan from "../components/Plan";
import Faq from "../components/Faq";
import { HashLink } from "react-router-hash-link";

const Vps = () => {
  const controlList = [
    {
      title: "High-Speed Network",
      disc: "Our VPS hosting comes with a high-speed network connection of up to 300 Mb/s, supported by a fiber-optic infrastructure, ensuring optimal performance for gaming, streaming, and other intensive applications.",
    },
    {
      title: "Firewall and DDoS Protection",
      disc: "Safeguard your virtual server with robust DDoS protection and easy-to-configure firewall settings, ensuring malicious traffic is effectively blocked to maintain your server’s integrity.",
    },
    {
      title: "Web-Based Terminal",
      disc: "Manage your VPS effortlessly from your browser using our web-based terminal. Execute commands, organize files, and adjust settings without the need for additional software installation.",
    },
    {
      title: "Scalable VPS Hosting",
      disc: "Begin with what you need and expand as your requirements grow. If your projects demand additional server resources, seamlessly upgrading to a more advanced VPS hosting plan is just a few clicks away. ",
    },
    {
      title: "99.9% Uptime Guarantee",
      disc: "Ensure your website remains accessible with our 99.9% uptime guarantee. Our reliable infrastructure minimizes downtime, keeping your online presence strong and stable.",
    },
    {
      title: "24/7 Expert Support",
      disc: "Get round-the-clock support from our team of hosting experts. Whether you need technical assistance or have a question about your VPS, we’re here to help you every step of the way.",
    },
  ];

  const planList = [
    {
      name: "Standard",
      disc: "Launch your own virtual server today.",
      price: "33",
      per: "/month",
      features: [
        "2GB RAM",
        "2 core CPU",
        "120 GB SSD",
        "Unmetered bandwidth",
        "cPanel License Fee Included",
      ],
    },
    {
      name: "Advanced",
      disc: "Increased storage gives you more space to explore and create.",
      price: "44",
      per: "/month",
      features: [
        "4GB RAM",
        "2 core CPU",
        "165 GB SSD",
        "Unmetered bandwidth",
        "cPanel License Fee Included",
      ],
      alt: true,
    },
    {
      name: "Premium",
      disc: "An ideal blend of power and resources.",
      price: "99",
      per: "/month",
      features: [
        "8GB RAM",
        "4 core CPU",
        "240 GB SSD",
        "Unmetered bandwidth",
        "cPanel License Fee Included",
      ],
    },
  ];

  const faqList = [
    {
      question: "What is Virtual Private Server Hosting?",
      answer: `VPS Hosting, or Virtual Private Server Hosting, offers a semi-dedicated environment through server virtualization. It allocates specific amounts of RAM and CPU to each account, ensuring dedicated resources without the cost of a full dedicated server. `,
    },
    {
      question: "Can a VPS Hosting be used to host a website?",
      answer: `Absolutely, with Metoda, hosting your website on a VPS is available! This is an ideal solution for websites requiring more resources and control than what is available with traditional web hosting. A VPS provides increased dedicated resources and the flexibility to tailor your server setup according to your unique requirements. `,
    },
    {
      question: "What VPS Hosting is used for?",
      answer: `VPS Hosting stands as a robust solution for website owners seeking to assert command over their digital footprint. Perfectly suited for managing heavy traffic volumes, it provides the versatility to tailor server settings to precise requirements. Whether it’s for a personal blog or a commercial website, VPS hosting is a strategic tool to elevate your online visibility.`,
    },
    //     {
    //       question: "What are VPS Hosting advantages? ",
    //       answer: `With VPS web hosting, users receive assured resources for their account, ensuring the
    //  designated amounts of RAM, CPU, and Disk Space are exclusively theirs, unaffected by
    //  the activities of other server users. This dedicated allocation enhances the stability
    //  and efficiency of your website. Moreover, your hosting environment is isolated,
    //  meaning you don't share the Operating System with anyone else, which significantly
    //  improves the security of your website's files.`,
    //     },
    {
      question: "Can I transfer my domain to your service?",
      answer:
        "Yes, you can transfer your domain to our service. The process typically involves obtaining an authorization (EPP) code from your current provider, unlocking the domain, and initiating the transfer request through our platform. Once the transfer is started, it may take a few days to complete, depending on the domain extension and the current provider's policies. Our support team is available to assist you through each step of the process.",
    },
    {
      question:
        "How can you obtain a professional email account with a VPS Hosting plan? ",
      answer: `Opting for a VPS Hosting plan enables the creation of professional email accounts under your own domain name. Many hosting services, including Metoda, provide email hosting within their offerings, with Metoda featuring Google Workspace solutions. A professional email address plays a vital role in cultivating trust with your audience and solidifying your brand's online presence. 
`,
    },
    {
      question: "How much bandwidth comes with a VPS Hosting plan?",
      answer: `The bandwidth included in a VPS Hosting package varies by the service provider and the specific plan selected. Our offerings include plans with bandwidth options of 1 TB, 2 TB, and 3 TB. Selecting a plan that provides sufficient bandwidth to accommodate your website's traffic volume and resource demands is crucial.`,
    },
    {
      question: "Can I upgrade my VPS Hosting to a higher plan?",
      answer: `Certainly, as your website expands and demands additional resources, you can elevate your VPS Hosting plan. We facilitate smooth upgrades, allowing you to enhance your hosting capacity effortlessly with just a few clicks. Choosing the appropriate Metoda plan ensures your website remains quick, dependable, and responsive, keeping pace with your growing audience. `,
    },
    {
      question: "What is SSD storage?",
      answer: `SSD storage revolutionizes website performance, offering speed, reliability, and superior read/write capabilities compared to traditional mechanical hard drives. Implementing SSD storage means your website loads more quickly, can manage greater volumes of traffic, and delivers an overall smoother experience for users`,
    },
    {
      question:
        "What is the difference between VPS Hosting and Dedicated Server? ",
      answer: `VPS Hosting and Dedicated Server present viable solutions for website proprietors seeking enhanced resources and autonomy beyond what standard web hosting delivers. Opting for a dedicated server grants you exclusive access to a server solely for your website's use. On the other hand, VPS Hosting allocates dedicated resources within a communal server environment. Both avenues promise impressive performance and the ability to scale, ultimately hinging on your unique requirements and financial plan. `,
    },
    {
      question: "What is the difference between VPS Hosting and Web Hosting?",
      answer: `Opting for a Web Hosting package places you among several users sharing a server to host their websites, which implies that your site doesn't have guaranteed access to specific resources like RAM and CPU. On the other hand, Virtual Private Server (VPS) Hosting offers increased space and bandwidth, accommodating a larger volume of traffic to your site. Unlike standard web hosting, VPS hosting supports the gradual expansion of your website, allowing for easy scaling as your site's popularity grows. VPS hosting also grants users greater control over their hosting environment, enabling personalized configuration adjustments. Discover the differences and advantages of VPS hosting compared to traditional web hosting. `,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="vps">
      <div className="vps__blur">
        <section className="hero">
          <div className="hero__header">
            <img
              src={headerBg}
              alt=""
              style={{ width: "80vw", maxWidth: "800px" }}
            />
            <h1 className="hero__header__title">
              <span>Virtual </span>
              <div>Private Server</div>
            </h1>
          </div>

          <p className="hero__disc" style={{ maxWidth: 500 }}>
            Secure, fast, and reliable - VPS for every business. Choose a VPS
            solution tailored to your needs.
          </p>
          <div className="hero__action" style={{ marginBottom: 0 }}>
            <HashLink to="#pricing">
              <button>
                <span>Get Started</span>
              </button>
            </HashLink>
            <p>from$2.99/mo*</p>
          </div>

          <img
            src={heroVps}
            className="hero__img vpsHero"
            alt=""
            // style={{ position: "relative", top: "-30px" }}
          />
        </section>

        <section className="vps__design">
          <h2
            className="whyWork__main__title"
            style={{ letterSpacing: "-1px" }}
          >
            <span>VPS Hosting</span> designed to <span>empower</span> you
          </h2>

          <p className="vps__design__disc">
            {/* We provide administrators,developers and designers the freedom to
            create without boundaries, boasting substantial computational
            capacity, complete root access, and dedicated server resources
            exclusively for their use. */}
            Experience unmatched performance, security, and flexibility with our
            tailored VPS solutions
          </p>

          <div className="vps__design__tree">
            <div className="vps__design__tree__midline"></div>

            <div className="vps__design__tree__item">
              <div className="vps__design__tree__item__main">
                <div className="vps__design__tree__item__main__top">
                  <h4>
                    VPS Server <br /> Management
                  </h4>

                  <img src={treeIcon1} alt="" />
                </div>

                <p>
                  {/* Enhance your account by incorporating additional VPS, Shared,
                  or Dedicated hosting services, and oversee everything
                  conveniently from a single location.{" "} */}
                  Manage your VPS hosting effortlessly with cPanel, a
                  user-friendly control panel that provides all the tools you
                  need to oversee your server and website operations.
                </p>
              </div>

              <div className="vps__design__tree__item__line"></div>
            </div>
            <div className="vps__design__tree__item alt">
              <div className="vps__design__tree__item__line"></div>

              <div className="vps__design__tree__item__main">
                <div className="vps__design__tree__item__main__top">
                  <h4>
                    {/* Permission <br /> Management */}
                    Automated <br /> Backups
                  </h4>

                  <img src={treeIcon4} alt="" />
                </div>

                <p>
                  Protect your data with automated backups that ensure quick and
                  easy recovery of your website in case of unexpected issues or
                  data loss.
                </p>
              </div>
            </div>
            <div className="vps__design__tree__item alt2">
              <div className="vps__design__tree__item__main ">
                <div className="vps__design__tree__item__main__top">
                  <h4>
                    Root Privileges <br /> Establish
                  </h4>

                  <img src={treeIcon2} alt="" />
                </div>

                <p>
                  An unlimited number of FTP accounts to manage file uploads,
                  download, and modifications on your virtual server.
                </p>
              </div>

              <div className="vps__design__tree__item__line"></div>
            </div>
          </div>
        </section>

        <section className="vps__control">
          <div className="vps__control__top">
            <h2
              className="whyWork__main__title"
              style={{ letterSpacing: "-1px" }}
            >
              If it's <span>control you seek,</span> you've just{" "}
              <span>discovered</span> it.
            </h2>
            <p>
              Our VPS hosting places you firmly in control, equipping you with
              the tools and resources needed to create amazing websites.{" "}
            </p>
          </div>

          <div className="vps__control__list">
            {controlList.map((item, i) => {
              return (
                <div className="vps__control__list__item" key={item.title}>
                  <span>{i + 1}</span>
                  <h4>{item.title}</h4>
                  <p>{item.disc} </p>
                </div>
              );
            })}
          </div>
        </section>
      </div>

      <Plan planList={planList} alt={true} vps={true} />

      <Faq faqList={faqList} alt={true} />
    </div>
  );
};

export default Vps;
