import React from "react";
import check from "../assets/images/svg/check.svg";
import { HashLink } from "react-router-hash-link";

const Plan = ({ planList, alt, vps, email, hosting, shared }) => {
  return (
    <section id="pricing" className={alt ? "plan alt" : "plan"}>
      <h2 className="whyWork__main__title">
        <span>Plans</span> & <span>Pricing</span>
      </h2>
      <p className="plan__disc">
        We offer flexible pricing plans tailored to meet your specific needs and
        budget
      </p>

      <div className={email ? "plan__list alt" : "plan__list"}>
        {planList.map((item) => {
          return (
            <div key={item.name} className="plan__list__item">
              <div>
                <h4>{item.name}</h4>
                <p>
                  {item.disc
                    ? item.disc
                    : `Experience the excellence of our services with a handful of
                    small projects.`}
                </p>

                {hosting && <p style={{ marginBottom: 0 }}>Starting from</p>}
                <div className="plan__list__item__price">
                  <span>$</span> <b>{item.price}</b>
                  {vps ? "" : item.per}
                </div>
                {!hosting && (
                  <p>
                    {vps
                      ? "Top Features"
                      : shared
                      ? "(1st year)"
                      : "Starting from"}
                  </p>
                )}
              </div>

              <div>
                <ul className="plan__list__item__list">
                  {item.features.map((feature) => {
                    return (
                      <li key={feature}>
                        <img src={check} alt="" /> {feature}
                      </li>
                    );
                  })}
                </ul>

                {item?.link ? (
                  <HashLink to={item?.link}>
                    <button className="plan__list__item__btn">
                      <span>Get Started</span>
                    </button>
                  </HashLink>
                ) : (
                  <button className="plan__list__item__btn">
                    <span>Get Started</span>
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Plan;
