import React from "react";
import work1Icon from "../assets/images/svg/work1.svg";
import work2Icon from "../assets/images/svg/work2.svg";
import work3Icon from "../assets/images/svg/work3.svg";
import work4Icon from "../assets/images/svg/work4.svg";
import work5Icon from "../assets/images/svg/work5.svg";
// import work6Icon from "../assets/images/svg/work6.svg";
import work7Icon from "../assets/images/svg/work7.svg";
import eclipes from "../assets/images/arcWhy.png";
import { HashLink } from "react-router-hash-link";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const WhyWork = () => {
  const list = [
    {
      title: "Top Quality",
      disc: "Design professionals that deliver top-notch masterpieces whenever you crave it!",
      img: work1Icon,
    },
    {
      title: "Customer Support",
      disc: `Availability of expert technical support 24/7 through various channels to quickly resolve issues.`,
      img: work2Icon,
    },
    {
      title: "Super Speedy",
      disc: `Transform your design into review-ready perfection in only 6-9 business days on average.`,
      img: work3Icon,
    },
    {
      title: "Reliability",
      disc: `Count on us for unwavering reliability, with secure and always available services you can trust.`,
      img: work4Icon,
    },
    {
      title: "Flexible",
      disc: `A team of flexible and
highly professional experts
on your beck and call.`,
      img: work5Icon,
    },
    {
      title: "Transparent Pricing",
      disc: `Clear and transparent pricing without hidden fees, along with competitive rates and various payment options.`,
      img: work7Icon,
    },
  ];

  return (
    <section className="whyWork">
      <div className="whyWork__main">
        <h2 className="whyWork__main__title">
          Why <span>work</span> with <span>Metoda?</span>
        </h2>

        <div className="whyWork__main__arc">
          <img src={eclipes} className="whyWork__main__arc__img" alt="" />
          <p className="whyWork__main__disc">
            With a relentless focus on innovation, quality, and client
            satisfaction, we offer a unique blend of expertise, creativity, and
            collaborative spirit that sets us apart.
          </p>

          <div className="whyWork__main__list">
            {list.map((item) => {
              return (
                <div key={item.title} className="whyWork__main__list__item">
                  <img src={item.img} alt="" />
                  <h4>{item.title}</h4>
                  <p>{item.disc}</p>
                </div>
              );
            })}
          </div>
          <div className="whyWork__main__list__mobile">
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              pagination={true}
              loop={true}
              modules={[Pagination, Navigation]}
              style={{ paddingBottom: 25 }}
              navigation={true}
            >
              {list.map((item) => {
                return (
                  <SwiperSlide>
                    <div
                      key={item.title}
                      style={{ width: "70%", margin: "0 auto" }}
                      className="whyWork__main__list__item"
                    >
                      <img src={item.img} alt="" />
                      <h4>{item.title}</h4>
                      <p>{item.disc}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="whyWork__main__btn">
            <HashLink to="#pricing">
              <button>
                <span>Get Started</span>
              </button>
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWork;
