import React, { useEffect } from "react";
import aboutHb from "../assets/images/svg/aboutHb.svg";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <div className="about__main">
        <div className="about__main__header">
          <div className="about__main__header__main">
            <img src={aboutHb} alt="" />
            <h1>About Us</h1>
          </div>
        </div>

        <div className="about__main__disc">
          <h3>Experience the Future of Digital Services with Metoda doo</h3>

          <p>
            Founded in November 2020, Metoda doo has been developing its
            services with the goal of quickly becoming a leader in digital
            services for individuals and businesses across Serbia. Based in
            Belgrade, we are dedicated to delivering innovative solutions
            tailored to the dynamic needs of today’s digital landscape.
          </p>
          <p>
            Metoda doo specializes in comprehensive hosting and domain services.
            As a modern data center, we offer robust and reliable hosting
            solutions designed to support your business’s digital
            infrastructure. Whether you need domain registration, web hosting,
            email services, or advanced web design, we have the expertise and
            technology to meet your needs.
          </p>
          <p>
            At Metoda doo, we are committed to providing high-quality services
            that ensure efficiency and maintain the highest standards of quality
            for your digital presence.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
